import React, {useState, useEffect, useRef} from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { DefaultPlayer as Video } from 'react-html5video';
import "react-html5video/dist/styles.css";
import "../../pages/bodyPages.css";

function Videowidget({data}) {

  const tabletSize = useMediaQuery("(min-width: 1100px)");
  const [selecteur, setSelecteur] = useState(0);
  const [selectedvideo, setSelectedvideo] = useState(data[0]);
  const [model, setModel] = useState(false);

  useEffect(() => { setSelectedvideo(data[selecteur - 1]);
                    //openModel(); 
                  }, [selecteur, model]);
  //const vidRef = useRef(null);
  

  const divRef = useRef(null)
                const openModel = () => {
                    divRef.current.classList.remove('video');
                    divRef.current.classList.add('model');
                    setModel(true);
                }
                const closeModel = () => {
                  divRef.current.classList.add('video');
                  divRef.current.classList.remove('model');
                  setModel(false)
                }

  return (
    <Box  width={"100%"} position={"relative"} padding={"20px 0"} display={"flex"} 
          flexDirection={tabletSize ? "row" : "column"} alignItems={"center"} justifyContent={"center"}>
        <div className="onplay" ref={divRef}>
            
              <Video style={{width:"95%"}}
                   autoPlay={model}
                   controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                   poster={selectedvideo?.image} >
                   <source src={selectedvideo?.videoURI} type='video/webm' />
            </Video>
            
        </div>
        <div className="playlist">
            { data.map((item, index) => {

                return <div className="videoItem" key={index} onClick={() => {setSelecteur(item?.id);
                                                                              openModel();}}>
                            <div className="poster">
                                <img src={item?.image} alt="" width="100%" height="90%" />
                            </div>
                            <div className="infoVideo">
                                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} 
                                     justifyContent={"flex-start"} marginBottom={"5px"}> 
                                     <Typography sx={{fontSize:"12px", color:"black", fontWeight:"bold", 
                                                      marginRight:"10px", textTransform:"uppercase", 
                                                      fontFamily:"Teko", bgcolor:"#be13c4", padding:"5px 10px"}}> 
                                                {item?.category} 
                                     </Typography>
                                     <Typography  sx={{color:"#fff"}}> / </Typography>
                                     <Typography sx={{fontSize:"10px", color:"#fff", marginLeft:"10px", 
                                                      fontFamily:"Teko"}}> 
                                                {item?.date} 
                                      </Typography>
                                </Box>
                                <Typography sx={{fontSize:"16px", color:"#fff", fontWeight:"bold", 
                                            fontFamily:'Teko', lineHeight:1.0, marginBottom:"10px"}}> 
                                          {item?.titre} 
                                </Typography>
                                <Typography sx={{fontSize:"16px", color:"#ccc", fontWeight:"bold", 
                                            fontFamily:'Teko', lineHeight:1.0, fontStyle:"italic"}}> 
                                          {item?.auteur} 
                                </Typography>
                            </div>
                        </div>                                                           
                       }) }
        </div>
    </Box>
  )
}

export default Videowidget