import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Statistiques from '../../components/study/Statistiques';
import Home from '../../components/study/Home';
import Localisation from '../../components/study/Localisation';

function Body() {
  const tabletSize = useMediaQuery("(min-width: 1000px)");
  const phoneSize = useMediaQuery("(min-width: 700px)");

return (
    <>
      <Box width={"100%"} bgcolor={"white"} position={"relative"} paddingX={"30px"}
           display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"400px"}>
          { tabletSize ? 
              <div className='bigSize' >

              </div> 
              :
              phoneSize ? 
                <div Name='mediumSize'>

                </div>
                :
                <div Name='smallSize'>

                </div> }
      </Box>
    </>
  )
}

export default Body