import React from 'react';
import "../../pages/bodyPages.css";
import { Box, Typography } from '@mui/material';

function CarteNewsUne({data}) {
  return (
    <div className='newsUne'>
        <div className="coverImage">
            <img src={data?.cover} alt='cover image' className='trendImage' />
        </div>
        <div className="newsTitles">
            <Box width={"100%"} height={"160px"} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} 
                 justifyContent={"flex-start"}>
                 <Box display={"flex"} flexDirection={"row"} alignItems={"center"} 
                      justifyContent={"flex-start"}> 
                      <Typography sx={{fontSize:"14px", color:"gray", fontWeight:"bold", marginRight:"10px", 
                                  textTransform:"uppercase", fontFamily:"Teko", color:"green"}}> {data?.category} </Typography>
                      <Typography> / </Typography>
                      <Typography sx={{fontSize:"12px", color:"gray", marginLeft:"10px", fontFamily:"Teko"}}> 
                            {data?.date} 
                      </Typography>
                 </Box>
                 <Typography sx={{fontSize:"28px", color:"white", fontWeight:"bold", fontFamily:'Teko', 
                             lineHeight:1.2}}> 
                             {data?.title} 
                </Typography>
                <Typography sx={{fontSize:"16px", color:"white", fontFamily:'Teko', 
                             lineHeight:1.2}}> 
                             {data?.details[0].title} 
                </Typography>
            </Box>
        </div>
    </div>
  )
}

export default CarteNewsUne