import { Box, Typography } from '@mui/material';
import React from 'react';
import "../../pages/bodyPages.css";

function CarteLatest({data}) {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"} justifyContent={"flex-start"}
         width={"100%"} height={"80px"} marginBottom={"15px"}>
        <Box width={"35%"} height={"80px"} marginRight={"10px"}>
            <img src={data?.cover} alt='image' width={"100%"} height={"70px"} className='trendImage' />
        </Box>
        <Box width={"85%"} height={"80px"} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} 
              justifyContent={"flex-start"}>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} 
                  justifyContent={"flex-start"}> 
                <Typography sx={{fontSize:"12px", color:"gray", fontWeight:"bold", marginRight:"10px", 
                            textTransform:"uppercase", fontFamily:"Teko"}}> {data?.category} </Typography>
                <Typography> / </Typography>
                <Typography sx={{fontSize:"10px", color:"gray", marginLeft:"10px", fontFamily:"Teko"}}> 
                            {data?.date} </Typography>
            </Box>
            <Typography sx={{fontSize:"16px", color:"gray", fontWeight:"bold", fontFamily:'Teko', lineHeight:0.9}}> 
                   {data?.title} 
            </Typography>
        </Box>
    </Box>
  )
}

export default CarteLatest