import React from 'react';
import Header from "../../components/header/Header";
import Body from "./Body";
import Footer from '../../components/footer/Footer';

function Shop() {
  return (
    <div style={{width:"100%"}}>
         <div className="header">
              <Header data={"shop"} color="#1870B7" />
         </div>
         <Body />
         <div>
             <Footer />
         </div>
    </div>
  )
}

export default Shop