import React, {useState} from 'react';
import HeadCover from './HeadCover';
import { Link, useNavigate } from 'react-router-dom';
import "./header.css";
import { Typography, Box, IconButton, useMediaQuery, Button, Menu, MenuItem, 
         Dialog, DialogTitle, DialogActions, Slide } from '@mui/material';
//import Flexbetween from '../styledcomponents/Flexbetween';
//import Flexcolumnright from '../styledcomponents/Flexcolumnright';
import MenuIcon from '@mui/icons-material/Menu';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { submenu } from '../../data/testData';
import Cartesubmenu from '../home/Cartesubmenu';
import CloseIcon from '@mui/icons-material/Close';
//import BookmarksIcon from '@mui/icons-material/Bookmarks';
//import LabelImportantIcon from '@mui/icons-material/LabelImportant';
//import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import SearchIcon from '@mui/icons-material/Search';


const Transition = React.forwardRef(function Transition(props, ref) {
     return <Slide direction="right" ref={ref} {...props} />;
   });
   

function Header({data, color}) {

    const [navbar, setNavbar] = useState(false);
    const [menu, setMenu] = useState("global");
    const tabletSize = useMediaQuery("(min-width: 1100px)");
    const phoneSize = useMediaQuery("(min-width: 750px)");
    const [Submenu, setSubmenu] = useState(0);
    const [submenudata, setSubmenudata] = useState(null);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
        <HeadCover data={data} />
        <div className={data === "study" ? "headBox02" : "headBox"}>
            { data === "study" ? 
              <Box width="100%" height="100%" display="flex" flexDirection="column" 
                   alignItems="center" justifyContent="center" >
                   <Box width="100%" height={tabletSize ? "12vh" : phoneSize ? "9vh" : "6vh"} display="flex" 
                        flexDirection="row" alignItems="center" justifyContent="space-between" 
                        paddingX={tabletSize ? "100px" : phoneSize ? "75px" : "50px"}>
                        <Typography sx={{color:"#fff", fontFamily:"Teko", fontWeight:"bold",
                                    fontSize:tabletSize ? "50px" : phoneSize ? "35px" : "25px"}} > 
                                  Q29 ETUDES 
                        </Typography>
                        <Box>
                            { phoneSize ? 
                            <>  
                            <IconButton sx={{color:"#fff"}} className='iconf'> <FacebookRoundedIcon /> </IconButton>
                            <IconButton sx={{color:"#fff"}} className='icont'> <TwitterIcon /> </IconButton>
                            <IconButton sx={{color:"#fff"}} className='iconi'> <InstagramIcon /> </IconButton>
                            <IconButton sx={{color:"#fff"}} className='icony'> <YouTubeIcon /> </IconButton>
                            </> : <></>}
                        </Box>
                   </Box>
                   <Box width="100%" height={tabletSize ? "12vh" : phoneSize ? "10vh" : "8vh"} display="flex" 
                        flexDirection="row" alignItems="center" justifyContent="center" >
                        {phoneSize ? 
                            <div className="menuEtude">
                                <div className="menuItemEtude"> ACCUEIL </div>
                                <div className="menuItemEtude"> STATISTIQUES </div>
                                <div className="menuItemEtude"> ORIENTATION </div>
                                <div className="menuItemEtude"> LOCALISATION </div>
                            </div> :
                         <div className="menuEtude">
                            <IconButton sx={{color:"#fff"}} onClick={() => setNavbar(true)} > 
                                    <MenuIcon fontSize='large' sx={{}} /> 
                            </IconButton>
                        </div>
                         }
                        <div className="menubarend">
                            { phoneSize ? "CERTICATIONS" : 
                            <IconButton sx={{color:"#fff"}} onClick={() => setNavbar(true)} > 
                                    <SearchIcon fontSize='small' sx={{}} /> 
                            </IconButton>  }
                        </div>
                   </Box>
                   <Box width="100%" height="70vh" display="flex" flexDirection="column" 
                        alignItems="flex-end" justifyContent="center" paddingLeft="100px">
                        <Box width={tabletSize ? "50%" : phoneSize ? "60%" : "80%"} marginBottom="20px">
                            <Typography sx={{color:"#fff", fontFamily:"Oswald", 
                                        fontSize:tabletSize ? "20px" : phoneSize ? "18px" : "16px"}}> 
                                       WELCOME TO Q29 ETUDES 
                            </Typography>
                        </Box>
                        <Box width={tabletSize ? "50%" : phoneSize ? "60%" : "80%"} marginBottom="20px">
                            <Typography sx={{color:"#fff", fontFamily:"Teko",  lineHeight:0.9, fontWeight:"bold", 
                                        fontSize:tabletSize ? "60px" : phoneSize ? "50px" : "40px",}}> 
                                       Best Online Education Expertise and Orientation
                            </Typography>
                        </Box>
                        <Box width={tabletSize ? "50%" : phoneSize ? "60%" : "80%"} paddingRight="20px">
                            <Typography sx={{color:"#fff", fontFamily:"Oswald", 
                                        fontSize:tabletSize ? "14px" : phoneSize ? "12px" : "10px"}}> 
                                        Par dela les frontieres, les cultures, les langues et toutes sortes 
                                        de clivages sociaux, Quoi2Neuf est avec vous comme la reference en 
                                        matiere de conseils et orientation pour les eleves et jeunes etudiants.
                            </Typography>
                        </Box>
                        <div className="boutonEtude">
                             <Button endIcon={<ArrowRightAltIcon />} sx={{bgcolor:"#1870B7", color:"#fff", width:"45%", 
                                     fontFamily:"Teko", fontSize:tabletSize ?"18px" : phoneSize ? "16px" : "14px", 
                                     marginRight:"15px"}} >
                                    GET STARTED NOW
                             </Button>
                             <Button endIcon={<ArrowRightAltIcon />} sx={{color:"#1870B7", bgcolor:"#fff", width:"45%", 
                                     fontFamily:"Teko", fontSize:tabletSize ?"18px" : phoneSize ? "15px" : "12px"}} >
                                    VIEW STATISTICS
                             </Button>
                        </div>
                   </Box>
              </Box> :
              <> </> }
        </div>
        <div className="headname" style={{color: color}}>
             { tabletSize ? 
                <Box width={tabletSize ? "25%" : 0}>
                  <IconButton sx={{color:"gray"}} className='iconf'> <FacebookRoundedIcon /> </IconButton>
                  <IconButton sx={{color:"gray"}} className='icont'> <TwitterIcon /> </IconButton>
                  <IconButton sx={{color:"gray"}} className='iconi'> <InstagramIcon /> </IconButton>
                  <IconButton sx={{color:"gray"}} className='icony'> <YouTubeIcon /> </IconButton>
             </Box> : <> </>}
             <Box display="flex" flexDirection="row" alignItems="baseline" width={tabletSize ? "50%" : "100%"} justifyContent="center">
                  <Typography sx={{fontSize:"75px", fontWeight:"bold", fontFamily:"Teko"}}>QUOI</Typography>
                  <Typography sx={{fontSize:"55px", fontWeight:"bold", fontFamily:"Teko"}}>2</Typography>
                  <Typography sx={{fontSize:"75px", fontWeight:"bold", fontFamily:"Teko"}}>NEUF</Typography>
             </Box>
             
             {  tabletSize ?
                <Box width={"25%"} display="flex" flexDirection="row-reverse">
                 <IconButton sx={{color:"gray"}} className='iconf'> <FavoriteIcon /> </IconButton>
             </Box> : <> </>}
        </div>
        <header className='headerMenu'>
                <div className='menuLogo' >
                     <IconButton sx={{color:"black"}} onClick={() => setNavbar(true)} > 
                                <MenuIcon fontSize='large' sx={{}} /> 
                     </IconButton>
                </div>
                        { data !== "study" ? 
                        <div className="container paddingSmall">
                              <nav>
                                   <ul className= 'flex' 
                                       onClick={() => setNavbar(false)}>
                                       <li> 
                                           <Link to='/'> Accueil </Link> 
                                        </li>
                                       <li onMouseEnter={() => { setSubmenu(1) }} 
                                           onMouseLeave={() => { setSubmenu(0) }}> 
                                           <Link to='/etudes'> Etudes </Link> 
                                        </li>
                                       <li onMouseEnter={() => { setSubmenu(2) }} 
                                           onMouseLeave={() => { setSubmenu(0) }}> 
                                           <Link to='/zoom'> Zoom </Link> 
                                        </li>
                                       <li onMouseEnter={() => { setSubmenu(3) }} 
                                           onMouseLeave={() => { setSubmenu(0) }}> 
                                           <Link to='/tendances'> Tendances </Link> 
                                        </li>
                                       <li onMouseEnter={() => { setSubmenu(4) }} 
                                           onMouseLeave={() => { setSubmenu(0) }}> 
                                           <Link to='/incubateur'> Incubateur </Link> 
                                        </li>
                                       <li onMouseEnter={() => { setSubmenu(5) }} 
                                           onMouseLeave={() => { setSubmenu(0) }}> 
                                           <Link to='/apropos'> A Propos </Link> 
                                        </li>
                                       <li onMouseEnter={() => { setSubmenu(6) }} 
                                           onMouseLeave={() => { setSubmenu(0) }}> 
                                           <Link to='/boutique'> Boutique </Link> 
                                        </li>
                                   </ul>
                              </nav>
                         </div>
                         :
                         <> </>}
                    <div className='accountLogo' >
                    <IconButton sx={{color:"gray"}}> 
                                <AccountCircleIcon /> 
                        </IconButton>
                    <div>
                         <Button id="basic-button"
                                 aria-controls={open ? 'basic-menu' : undefined}
                                 aria-haspopup="true"
                                 aria-expanded={open ? 'true' : undefined}
                                 onClick={handleClick}
                                 sx={{fontFamily:"Oswald", fontSize:'14', fontWeight:'500', color:'#000'}} >
                                Compte
                         </Button>
                         <Menu id="basic-menu"
                               anchorEl={anchorEl}
                               open={open}
                               onClose={handleClose}
                               MenuListProps={{'aria-labelledby': 'basic-button', }}>
                               <MenuItem onClick={handleClose}> Se Connecter </MenuItem>
                               <MenuItem onClick={handleClose}> Creer un Compte </MenuItem>
                         </Menu>
                    </div>
                    </div>
                    {Submenu !== 0 && 
                    <Box position="absolute" width="100%" bgcolor="#FFF" height="200px" 
                         top='34.8rem' borderBottom={"solid #000 1px"} zIndex={5} 
                         margin={"-30px"} borderTop={"solid #000 1px"} padding={"20px 30px"}
                         onMouseEnter={() => { setSubmenu(6) }} 
                         onMouseLeave={() => { setSubmenu(0) }} >
                        <Box width="100%" display="flex" flexDirection="row" alignItems="center" 
                             justifyContent="space-between">
                             {submenu[Submenu - 1]?.data.map(item => {
                                   return <Cartesubmenu data={item} key={item.id}/>
                             })}
                        </Box>
                    </Box>}
                 </header>
               
                    <Dialog open={navbar}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => setNavbar(false)}
                            sx={{height:"100vh", width:"360px", bgcolor:"#000"}}>
                            <DialogTitle sx={{height:"20vh", width:"290px", bgcolor:"#000", display:'flex',
                                              flexDirection:'row', alignItems:'center', justifyContent:'space-between',
                                              padding:"10px 15px"}}>
                                   <Box display="flex" flexDirection="row" alignItems="baseline" justifyContent="center">
                                       <Typography sx={{fontSize:"75px", fontWeight:"bold", color:'#672B82'}}>K</Typography>
                                       <Typography sx={{fontSize:"55px", fontWeight:"bold", color:'#672B82'}}>2</Typography>
                                       <Typography sx={{fontSize:"75px", fontWeight:"bold", color:'#672B82'}}>9</Typography>
                                   </Box>
                                   <IconButton sx={{color:'#fff'}} onClick={() => setNavbar(false)} className='closeIcon' >
                                          <CloseIcon fontSize='large'/>
                                   </IconButton>
                            </DialogTitle>
                            <DialogActions sx={{height:"60vh", width:"290px", bgcolor:"#000", display:'flex',
                                              flexDirection:'column', alignItems:'center', justifyContent:'center',
                                              padding:"10px 0px"}}>
                                   <div className='menuItemBox' onClick={() => {navigate("/"); setNavbar(false);}}>
                                       <div className='menuItemBoxText'> ACCUEIL </div>
                                   </div>
                                   <div className='menuItemBox' onClick={() => {navigate("/etudes"); setNavbar(false);}}>
                                       <div className='menuItemBoxText'> ETUDES </div>
                                   </div>
                                   <div className='menuItemBox' onClick={() => {navigate("/zoom"); setNavbar(false);}}>
                                       <div className='menuItemBoxText'> ZOOM </div>
                                   </div>
                                   <div className='menuItemBox' onClick={() => {navigate("/tendances"); setNavbar(false);}}>
                                       <div className='menuItemBoxText'> TENDANCES </div>
                                   </div>
                                   <div className='menuItemBox' onClick={() => {navigate("/incubateur"); setNavbar(false);}}>
                                       <div className='menuItemBoxText'> INCUBATEUR </div>
                                   </div>
                                   <div className='menuItemBox' onClick={() => {navigate("/apropos"); setNavbar(false);}}>
                                       <div className='menuItemBoxText'> A PROPOS </div>
                                   </div>
                                   <div className='menuItemBox' onClick={() => {navigate("/boutique"); setNavbar(false);}}>
                                       <div className='menuItemBoxText'> BOUTIQUE </div>
                                   </div>
                                   
                      </DialogActions>
                    </Dialog>
    </div>
  )
}

export default Header