import React from 'react';
import "../../pages/bodyPages.css";
import { Box, Typography } from '@mui/material';

function CarteAdvertisement({data}) {
  return (
    <div className='pub'>
        <Typography sx={{color:'gray', fontFamily:'Teko', fontSize:12, margin:"5px 0"}}> PUBLICITE </Typography>
        <div className="pubImageBox">
            <img src={data?.image} alt='cover image' className='pubImage' />
        </div>
    </div>
  )
}

export default CarteAdvertisement