import React from 'react';
import AboutUs from '../pages/aboutUs/AboutUs';
import Home from '../pages/home/Home';
import Incubator from '../pages/incubateur/Incubator';
import Shop from '../pages/shop/Shop';
import Study from '../pages/study/Study';
import Tendances from '../pages/tendances/Tendances';
import Zoom from '../pages/zoom/Zoom';
import { Routes, Route, useLocation } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';


function AnimatedRoutes() {

    const location = useLocation();
  return (
     <AnimatePresence>
          <Routes location={location} key={location.pathname}>
                            <Route path='/' element={<Home />} />
                            <Route path='/etudes' element={<Study />} />
                            <Route path='/zoom' element={<Zoom />} />
                            <Route path='/tendances' element={<Tendances />} />
                            <Route path='/incubateur' element={<Incubator />} />
                            <Route path='/apropos' element={<AboutUs />} />
                            <Route path='/boutique' element={<Shop />} />
        </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes