import React from 'react';
import "./footer.css";
import {Box, IconButton, Typography, useMediaQuery} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
//import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import FavoriteIcon from '@mui/icons-material/Favorite';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LaptopIcon from '@mui/icons-material/Laptop';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CopyrightIcon from '@mui/icons-material/Copyright';

function Footer() {

    const phoneSize = useMediaQuery("(min-width: 600px)");

  return (
    <div className='Footer'>
        <div className="box01">
            <div className="logo">
                <Box display="flex" flexDirection="row" alignItems="baseline" width={"100%"} justifyContent="center">
                    <Typography sx={{fontSize:"75px", fontWeight:"bold", fontFamily:"", color:"#672B82"}}>K</Typography>
                    <Typography sx={{fontSize:"55px", fontWeight:"bold", fontFamily:"", color:"#672B82"}}>2</Typography>
                    <Typography sx={{fontSize:"75px", fontWeight:"bold", fontFamily:"", color:"#672B82"}}>9</Typography>
                </Box>
            </div>
            <div className="reseaux">
                <div className="icon">
                    <IconButton sx={{color:"#fff"}} className="icon1" > <FacebookIcon /> </IconButton>
                </div>
                <div className="icon">
                    <IconButton sx={{color:"#fff"}} className="icon2"> <TwitterIcon /> </IconButton>
                </div>
                <div className="icon">
                    <IconButton sx={{color:"#fff"}} className="icon3"> <InstagramIcon /> </IconButton>
                </div>
                <div className="icon">
                    <IconButton sx={{color:"#fff"}} className="icon4"> <PinterestIcon /> </IconButton>
                </div>
                <div className="icon">
                    <IconButton sx={{color:"#fff"}} className="icon5"> <LinkedInIcon /> </IconButton>
                </div>
                <div className="icon">
                    <IconButton sx={{color:"#fff"}} className="icon6"> <WhatsAppIcon /> </IconButton>
                </div>
                <div className="icon">
                    <IconButton sx={{color:"#fff"}} className="icon7"> <YouTubeIcon /> </IconButton>
                </div>
                <div className="icon">
                    <IconButton sx={{color:"#fff"}} className="icon8"> <LaptopIcon /> </IconButton>
                </div>
            </div>
            <div className="menu">
                <Typography className="menuItem" 
                            sx={{fontFamily:'Oswald',fontSize: phoneSize ? 12 : 9, marginRight:"10px"}}> ETUDES </Typography>
                <Typography className="menuItem" 
                            sx={{fontFamily:'Oswald',fontSize: phoneSize ? 12 : 9, marginRight:"10px"}}> ZOOM </Typography>
                <Typography className="menuItem" 
                            sx={{fontFamily:'Oswald',fontSize: phoneSize ? 12 : 9, marginRight:"10px"}}> TENDANCES</Typography>
                <Typography className="menuItem" 
                            sx={{fontFamily:'Oswald',fontSize: phoneSize ? 12 : 9, marginRight:"10px"}}> INCUBATEUR </Typography>
                <Typography className="menuItem" 
                            sx={{fontFamily:'Oswald',fontSize: phoneSize ? 12 : 9, marginRight:"10px"}}> POINT2VUE</Typography>
                <Typography className="menuItem" 
                            sx={{fontFamily:'Oswald',fontSize: phoneSize ? 12 : 9, marginRight:"10px"}}> BOUTIQUE</Typography>
            </div>
        </div>
        <div className="box02">
            <Typography sx={{color:"gray", fontFamily:'Oswald',fontSize: phoneSize ? 13 : 10, marginRight:'2px'}}> 
                  Copyright 
            </Typography>
            <IconButton sx={{color:'#672B82'}}> <CopyrightIcon fontSize='small' /> </IconButton>
            <Typography sx={{color:"gray", fontFamily:'Oswald',fontSize: phoneSize ? 13 : 10, marginLeft:'2px'}}> 
                   2023 K29 Medias. Theme by KemaarSolutions, powered by Racine.
            </Typography>
        </div>
    </div>
  )
}

export default Footer