import { Box, Typography } from '@mui/material';
import React from 'react';
import "../../pages/bodyPages.css";

function Cartesubmenu({data}) {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} justifyContent={"flex-start"}
         width={"20%"} height={"180px"} marginBottom={"20px"} marginRight={"15px"}>
        <Box width={"100%"} height={"130px"}>
            <img src={data?.image1} alt='image' width={"100%"} height={"130px"} className='trendImage' />
        </Box>
        <Box width={"100%"} height={"50px"} display={"flex"} flexDirection={"row"}
             alignItems={"center"} justifyContent={"flex-start"}>
            <Typography sx={{fontSize:"15px", color:"gray", fontWeight:"bold", fontFamily:'Teko', lineHeight:0.9}}> 
                  {data?.titre} </Typography>
        </Box>
    </Box>
  )
}

export default Cartesubmenu