import React, {useState} from 'react';
import { Box, useMediaQuery } from '@mui/material';
import "../bodyPages.css";
import { popular } from '../../data/testData';
import { hero } from '../../data/testData';
import { pub } from '../../data/testData';
import CarteTendance from '../../components/home/CarteTendance';
import CarteNewsUne from '../../components/home/CarteNewsUne';
import CarteNews from '../../components/home/CarteNews';
import CarteLatest from '../../components/home/CarteLatest';
import CarteAdvertisement from '../../components/home/CarteAdvertisement';
import { Button } from '@mui/material';
import { videoDatas } from '../../data/videotestData';
import Videowidget from '../../components/home/Videowidget';

function Body() {

  const tabletSize = useMediaQuery("(min-width: 1100px)");
  const phoneSize = useMediaQuery("(min-width: 750px)");
  const data = popular.filter(item => {return item.id < 8});
  const [Data, setData] = useState(popular)
  const [trenData, setTrendData] = useState(data);
  const data2 = hero.filter(item => { return item.id < 5})
  const [newsData, setNewsData] = useState(data2);
  const [Pub, setPub] = useState(pub[0]);
  const [selected, setselected] = useState(1);
  const [videos, setVideos] = useState(videoDatas);
 

  return (
    <>
      <Box width={"100%"} bgcolor={"white"} position={"relative"} paddingX={"30px"}
           display={"flex"} alignItems={"center"} justifyContent={"center"} paddingTop={"15px"}>
          { tabletSize ? 
              <div className='bigSize' >
                  <div className="trending">
                      <div className="titlebox1">
                          <div className="trait"> </div>
                          <div className="button">
                              <Button sx={{bgcolor: "#672B82", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px", width:'150px' }}> 
                                  TENDANCES
                             </Button>
                          </div> 
                      </div>
                      <div className="contain" width="100%">
                          { trenData.map(item => {
                              return <CarteTendance data={item} key={item.id} />
                          }) }
                      </div>
                  </div>
                  <div className="actuality">
                      { newsData.map(item => {
                          if(item.id === 1) {
                            return <CarteNewsUne data={item} key={item.id}/>
                          }
                          else { return <CarteNews data={item} key={item.id}/>}
                      }) }
                  </div>
                  <div className="advertisement">
                      <div>
                           <CarteAdvertisement data={Pub} />
                      </div>
                      <div className='gallerie'>
                         <div className="titlebox">
                          <Button sx={{bgcolor:selected === 1 ? "#672B82" : "gray", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px" }} onClick={() => setselected(1)}> LATEST</Button>
                          <Button sx={{bgcolor:selected === 2 ? "#672B82" : "gray", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px"}} onClick={() => setselected(2)}> VIDEOs </Button>
                          <Button sx={{bgcolor:selected === 3 ? "#672B82" : "gray", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px"}} onClick={() => setselected(3)}> GALLERIE</Button>
                         </div>
                         <div>
                             {Data.map(item => {
                                return <CarteLatest data={item} key={item.id} />
                             })}
                         </div>
                           
                      </div>
                  </div>
              </div> 
              :
              phoneSize ? 
                <div className='mediumSize'>
                    <div className="hight">  
                    <div className="actuality">
                      { newsData.map(item => {
                          if(item.id === 1) {
                            return <CarteNewsUne data={item} key={item.id}/>
                          }
                          else { return <CarteNews data={item} key={item.id}/>}
                      }) }
                    </div>
                    <div className="trending">
                        <div className="titlebox1">
                          <div className="trait"> </div>
                          <div className="button">
                              <Button sx={{bgcolor: "#672B82", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px", width:'150px' }}> 
                                  TENDANCES
                             </Button>
                          </div> 
                      </div>
                        <div className="contain" width="100%">
                            { trenData.map(item => {
                              return <CarteTendance data={item} key={item.id} />
                            }) }
                        </div>
                    </div>
                    </div>
                    <div className="advertisement">
                    <div>
                           <CarteAdvertisement data={Pub} />
                      </div>
                      <div className='gallerie'>
                         <div className="titlebox">
                          <Button sx={{bgcolor:selected === 1 ? "#672B82" : "gray", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px" }} onClick={() => setselected(1)}> LATEST</Button>
                          <Button sx={{bgcolor:selected === 2 ? "#672B82" : "gray", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px"}} onClick={() => setselected(2)}> VIDEOs </Button>
                          <Button sx={{bgcolor:selected === 3 ? "#672B82" : "gray", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px"}} onClick={() => setselected(3)}> GALLERIE</Button>
                         </div>
                         <div>
                             {Data.map(item => {
                                return <CarteLatest data={item} key={item.id} />
                             })}
                         </div>
                           
                      </div>
                  </div>
                  </div>
                
                :
                <div className='smallSize'>
                    <div className="actuality">
                      { newsData.map(item => {
                          if(item.id === 1) {
                            return <CarteNewsUne data={item} key={item.id}/>
                          }
                          else { return <CarteNews data={item} key={item.id}/>}
                      }) }
                    </div>
                    <div className="trending">
                        <div className="titlebox1">
                          <div className="trait"> </div>
                          <div className="button">
                              <Button sx={{bgcolor: "#672B82", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px", width:'150px' }}> 
                                  TENDANCES
                             </Button>
                          </div> 
                      </div>
                        <div className="contain" width="100%">
                            { trenData.map(item => {
                              return <CarteTendance data={item} key={item.id} />
                            }) }
                        </div>
                    </div>
                    <div className="advertisement">
                    <div>
                           <CarteAdvertisement data={Pub} />
                      </div>
                      <div className='gallerie'>
                         <div className="titlebox">
                          <Button sx={{bgcolor:selected === 1 ? "#672B82" : "gray", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px" }} onClick={() => setselected(1)}> LATEST</Button>
                          <Button sx={{bgcolor:selected === 2 ? "#672B82" : "gray", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px"}} onClick={() => setselected(2)}> VIDEOs </Button>
                          <Button sx={{bgcolor:selected === 3 ? "#672B82" : "gray", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px"}} onClick={() => setselected(3)}> GALLERIE</Button>
                         </div>
                         <div>
                             {Data.map(item => {
                                return <CarteLatest data={item} key={item.id} />
                             })}
                         </div>
                           
                      </div>
                  </div>
                  </div>
                 }
      </Box>
      <Box width={"100%"} bgcolor={"white"} position={"relative"} paddingX={"30px"}
           display={"flex"} flexDirection={"column"} alignItems={"center"} 
           justifyContent={"flex-start"} minHeight="30rem">
          <div className="titlebox1">
                          <div className="trait"> </div>
                          <div className="button">
                              <Button sx={{bgcolor: "#672B82", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px", width:'150px' }}> 
                                  ZOOM
                             </Button>
                          </div> 
                      </div>
          <div className='entertainment'>

          </div>
      </Box>

      <Box width={"100%"} bgcolor={"black"} position={"relative"} padding={"40px 30px"}
           display={"flex"} flexDirection={"column"} alignItems={"center"} 
           justifyContent={"center"} minHeight="30rem">
           <div className="titlebox1">
                <div className="trait2"> </div>
                <div className="button">
                    <Button sx={{bgcolor: "#672B82", color:"#fff", fontFamily:"Oswald", fontSize:'18px',
                            fontStyle:'italic', fontWeight:'bold', margin:"5px 10px", width:'150px' }}> 
                            VIDEOS
                    </Button>
                </div> 
            </div>
          <div className='entertainment'>
               <Videowidget data={videos} />
          </div>
      </Box>

      <Box width={"100%"} bgcolor={"white"} position={"relative"} padding={"20px 30px"}
           display={"flex"} flexDirection={"column"} alignItems={"center"} 
           justifyContent={"flex-start"} minHeight="30rem">
          <div className="titlebox1">
                          <div className="trait"> </div>
                          <div className="button">
                              <Button sx={{bgcolor: "#672B82", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px", width:'150px' }}> 
                                  INCUBATEUR
                             </Button>
                          </div> 
                      </div>
          <div className='entertainment'>

          </div>
      </Box>

      <Box width={"100%"} bgcolor={"black"} position={"relative"} padding={"20px 30px"}
           display={"flex"} flexDirection={"column"} alignItems={"center"} 
           justifyContent={"flex-start"} minHeight="30rem">
          <div className="titlebox1">
                          <div className="trait2"> </div>
                          <div className="button">
                              <Button sx={{bgcolor: "#672B82", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px", width:'150px' }}> 
                                  BOUTIQUE
                             </Button>
                          </div> 
                      </div>
          <div className='entertainment'>

          </div>
      </Box>

      <Box width={"100%"} bgcolor={"white"} position={"relative"} padding={"20px 30px"}
           display={"flex"} flexDirection={"column"} alignItems={"center"} 
           justifyContent={"flex-start"} minHeight="30rem">
          <div className="titlebox1">
                          <div className="trait"> </div>
                          <div className="button">
                              <Button sx={{bgcolor: "#672B82", color:"#fff", fontFamily:"Oswald", 
                                  fontStyle:'italic', fontWeight:'bold', margin:"5px 10px", width:'150px' }}> 
                                  ENTERTAINMENT
                             </Button>
                          </div> 
                      </div>
          <div className='entertainment'>

          </div>
      </Box>
    </>
  )
}

export default Body