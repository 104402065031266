import React from 'react';
import Header from "../../components/header/Header";
import Body from "./Body";
import Footer from '../../components/footer/Footer';

function Incubator() {
  return (
    <div>
         <div className="header">
              <Header data={"incubateur"} />
         </div>
         <Body />
         <div>
             <Footer />
         </div>
    </div>
  )
}

export default Incubator