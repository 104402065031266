import { Box, Typography } from '@mui/material';
import React from 'react';
import "./card.css";


function Card({data}) {
  return (
    <div className='card'>
        <div className='card_img'>
             <img src={data?.image} alt="" />
        </div>
        <div className='card_header'>
            <Typography fontSize={"18px"} color={"#000"} >
                       {data?.product_name}
            </Typography>
            <Typography fontSize={"14px"} color={"#000"} textAlign={"center"}>
                       {data?.description}
            </Typography>
            <Typography fontSize={"25px"} color={"#FF9A24"}> {data?.price}<span> {data?.currency} </span> </Typography>
            <div className="btn"> Ajouter au panier </div>
        </div>
    </div>
  )
}

export default Card