import React from 'react';
//import headImage from "../../assets/headerCover01.png";
import "./header.css";

function HeadCover({data}) {
  return (
    <div>
         <section className={data === 'home' ? 'head' : data === 'study' ? 'head02' : 
                             data === 'tendances' ? 'head03' : data === 'zoom' ? 'head04' : 
                             data === 'incubateur' ? 'head05' : data === 'shop' ? 'head06' : 'head07' }>
                    <div className="logo">
                        
                    </div>    
        </section>
    </div>
  )
}

export default HeadCover

//<img src={headImage} alt="logo" width={"100%"} />