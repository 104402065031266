import React from 'react';
import Header from "../../components/header/Header";
import Body from "./Body";
import Footer from '../../components/footer/Footer';

function Zoom() {
  return (
    <div>
         <div className="header">
              <Header data={"zoom"} />
         </div>
         <Body />
         <div>
             <Footer />
         </div>
    </div>
  )
}

export default Zoom