import image1 from "../assets/videos/image1.PNG";
import image2 from "../assets/videos/image2.PNG";
import image3 from "../assets/videos/image3.PNG";
import image4 from "../assets/videos/image4.PNG";
import video1 from "../assets/videos/video1.mp4";
import video2 from "../assets/videos/video2.mp4";
import video3 from "../assets/videos/video3.mp4";
import video4 from "../assets/videos/video4.mp4";

export const videoDatas = [
    {
        id: 1,
        category: "musique",
        titre: "Quand J'aurai l'Argent!",
        auteur: "Ko-C ft Innoss B",
        image: image1,
        videoURI: video1,
        date: "15-Oct 2023"
    },
    {
        id: 2,
        category: "musique",
        titre: "Please don't call me back!!",
        auteur: "Locko",
        image: image2,
        videoURI: video2,
        date: "15-Oct 2023"
    },
    {
        id: 3,
        category: "musique",
        titre: "Emilianna",
        auteur: "Ckay",
        image: image3,
        videoURI: video3,
        date: "15-Oct 2023"
    },
    {
        id: 4,
        category: "musique",
        titre: "J'avoue! J'avoue",
        auteur: "Sensey ft Hiro",
        image: image4,
        videoURI: video4,
        date: "15-Oct 2023"
    },
]