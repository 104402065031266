import React, {useState} from 'react';
import { Box, Grid, useMediaQuery, Container, Paper } from '@mui/material';
import { products } from '../../data/productDatas';
import Card from '../../components/shop/Card';
import CssBaseline from '@mui/material/CssBaseline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Badge from '@mui/material/Badge';
import "../bodyPages.css"

function Body() {
  const tabletSize = useMediaQuery("(min-width: 1000px)");
  const phoneSize = useMediaQuery("(min-width: 600px)");
  const [shop_products, setShop_products] = useState(products);
  const [counter, setCounter] = useState(0);


  return (
      <Box width={"100%"} bgcolor={"white"} display={"flex"} flexDirection={"column"} 
           alignItems={"center"} justifyContent={"center"} minHeight={"400px"} paddingY={"10px"} >
          <CssBaseline />
          <div className="shophead">
              <Box></Box>
              <Box>
                  <Badge badgeContent={counter} visible={counter ==! 0} color="success">
                        <ShoppingCartIcon color="action" />
                  </Badge>
              </Box>
          </div>
          <Container sx={{display:"flex", alignItems:"center",justifyContent:"center", 
                           }}>  
               <Grid container rowSpacing={12} columnSpacing={{ xs: 1, sm: 2, md: 3 }} 
                     sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    {shop_products.map((item, index) => {
                          return  <Grid item xs={tabletSize ? 4 : phoneSize ? 6 : 12} key={index} > 
                                      <Box sx={{width:"100%", height:"400px", margin:"20px",
                                             display:"flex", alignItems:"center", justifyContent:"center"}}>  
                                            <Card  data={item} /> 
                                      </Box>
                                  </Grid>
                  })}
                </Grid>
          </Container>
      </Box>
  )
}

export default Body