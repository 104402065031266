import image01 from "../assets/shop/car01.png";
import image02 from "../assets/shop/car02.png";
import image03 from "../assets/shop/car03.png";
import image04 from "../assets/shop/car04.png";
import image05 from "../assets/shop/car05.png";

export const products = [
    {
        id: 1,
        product_name: "X-tigi plus Ultra",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 350,
        currency: "$",
        image: image01,
    },
    {
        id: 2,
        product_name: "X-tigi Ultra",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 250,
        currency: "$",
        image: image02,
    },
    {
        id: 3,
        product_name: "X-tigi plus Alpha",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 150,
        currency: "$",
        image: image03,
    },
    {
        id: 4,
        product_name: "X-tigi Alpha",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 340,
        currency: "$",
        image: image02,
    },
    {
        id: 5,
        product_name: "X-tigi plus Omega",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 240,
        currency: "$",
        image: image03,
    },
    {
        id: 6,
        product_name: "X-tigi Omega",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 140,
        currency: "$",
        image: image04,
    },
    {
        id: 7,
        product_name: "X-tigi plus Beta",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 320,
        currency: "$",
        image: image03,
    },
    {
        id: 8,
        product_name: "X-tigi Beta",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 220,
        currency: "$",
        image: image04,
    },
    {
        id: 9,
        product_name: "X-tigi plus Epsilon",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 120,
        currency: "$",
        image: image05,
    },
    {
        id: 10,
        product_name: "X-tigi Epsilon",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 300,
        currency: "$",
        image: image04,
    },
    {
        id: 11,
        product_name: "X-tigi plus Iota",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 200,
        currency: "$",
        image: image05,
    },
    {
        id: 12,
        product_name: "X-tigi Iota",
        description: "blablabla blabla bla blablablabla bla blablablabla bla blabla bla",
        price: 100,
        currency: "$",
        image: image01,
    },
]