import { Box, Typography } from '@mui/material';
import React from 'react';
import "../../pages/bodyPages.css";

function CarteTendance({data}) {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} justifyContent={"flex-start"}
         width={"100%"} height={"210px"} marginBottom={"20px"}>
        <Box width={"100%"} height={"150px"}>
            <img src={data?.cover} alt='image' width={"100%"} height={"145px"} className='trendImage' />
        </Box>
        <Box width={"100%"} height={"60px"} display={"flex"} flexDirection={"row"}
             alignItems={"flex-start"} justifyContent={"flex-start"}>
            <Box width={"15%"} height={"60px"} display={"flex"} flexDirection={"row"} alignItems={"flex-start"} 
                 justifyContent={"center"}>
                 <Typography sx={{fontSize:"40px", fontWeight:"bold", color:"gray"}} > {data?.id} </Typography>
            </Box>
            <Box width={"85%"} height={"60px"} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} 
                 justifyContent={"flex-start"}>
                 <Box display={"flex"} flexDirection={"row"} alignItems={"center"} 
                      justifyContent={"flex-start"}> 
                      <Typography sx={{fontSize:"14px", color:"gray", fontWeight:"bold", marginRight:"10px", 
                                  textTransform:"uppercase", fontFamily:"Teko"}}> {data?.category} </Typography>
                      <Typography> / </Typography>
                      <Typography sx={{fontSize:"12px", color:"gray", marginLeft:"10px", fontFamily:"Teko"}}> 
                            {data?.date} </Typography>
                 </Box>
                 <Typography sx={{fontSize:"20px", color:"black", fontWeight:"bold", fontFamily:'Teko', lineHeight:0.9}}> {data?.title} </Typography>
            </Box>
        </Box>
    </Box>
  )
}

export default CarteTendance