//import { BrowserRouter} from 'react-router-dom';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AnimatedRoutes from "./components/AnimatedRoutes.js";



function App() {
  return (
        <div className='app' font-familly= "roboto">
            <div className='app'>
                    <BrowserRouter>
                                <AnimatedRoutes />
                    </BrowserRouter>
            </div>
       </div>
  );
}

export default App;


