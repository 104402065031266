import cover01 from "../assets/cover01.png";
import cover02 from "../assets/cover02.JPG";
import cover03 from "../assets/cover11.JPG";
import cover04 from "../assets/cover05.jpg";
import cover09 from "../assets/cover09.JPG";
import cover05 from "../assets/cover08.JPG";
import cover06 from "../assets/cover10.PNG";
import image01 from "../assets/head01.JPG";
import imageS1 from "../assets/head04.JPG";
import imageS2 from "../assets/cover01.JPG";

export const hero = [
    {
        id:1,
        cover: cover01,
        category: "featured",
        title: "KOI 2 NEUF, MEDIA EMPIRE",
        authorname: "Racine",
        authorImg: "",
        time: "29 Sept - 2023",
        desc: [
            {
                para1: "blakjsfkjkjvdfj ,ks k kdjh  gkj jbgk lwhfg wu wih i kw vsbg kb wkb kw  kw gfksjhgf wkjbs",
            },
            {
                para2: "Nous travaillons avec des clients, petits et grands, de tout secteurs d'activite!!",
            },
            {
                para3: "Nous avons plusieurs differentes equipes a travers le monde pour vos fournir les meilleurs services la ou vous vous trouvez",
            }
        ],
        details: [
            {title: "Commencer une nouvelle entreprise est facile!!"},
            {
                para1: "Nos equipes sont au fait des dernieres technologies et ionnovations pour vous faciliter la vie!"
            },
            {
                quote: "bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bal bla"
            }
        ]
    },
    {
        id:2,
        cover: cover02,
        category: "inside",
        title: "Grow Up easily",
        authorname: "Marking",
        authorImg: "",
        time: "27 Sept - 2023",
        desc: [
            {
                para1: "blakjsfkjkjvdfj ,ks k kdjh  gkj jbgk lwhfg wu wih i kw vsbg kb wkb kw  kw gfksjhgf wkjbs",
            },
            {
                para2: "Nous travaillons avec des clients, petits et grands, de tout secteurs d'activite!!",
            },
            {
                para3: "Nous avons plusieurs differentes equipes a travers le monde pour vos fournir les meilleurs services la ou vous vous trouvez",
            }
        ],
        details: [
            {title: "Commencer une nouvelle entreprise est facile!!"},
            {
                para1: "Nos equipes sont au fait des dernieres technologies et ionnovations pour vous faciliter la vie!"
            },
            {
                quote: "bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bal bla"
            }
        ]
    },
    {
        id:3,
        cover: cover03,
        category: "technology",
        title: "Intellignece Artificielle",
        authorname: "Racine",
        authorImg: "",
        time: "29 Sept - 2023",
        desc: [
            {
                para1: "blakjsfkjkjvdfj ,ks k kdjh  gkj jbgk lwhfg wu wih i kw vsbg kb wkb kw  kw gfksjhgf wkjbs",
            },
            {
                para2: "Nous travaillons avec des clients, petits et grands, de tout secteurs d'activite!!",
            },
            {
                para3: "Nous avons plusieurs differentes equipes a travers le monde pour vos fournir les meilleurs services la ou vous vous trouvez",
            }
        ],
        details: [
            {title: "Commencer une nouvelle entreprise est facile!!"},
            {
                para1: "Nos equipes sont au fait des dernieres technologies et ionnovations pour vous faciliter la vie!"
            },
            {
                quote: "bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bal bla"
            }
        ]
    },
    {
        id:4,
        cover: cover04,
        category: "featured",
        title: "Space X",
        authorname: "Racine",
        authorImg: "",
        time: "29 Sept - 2023",
        desc: [
            {
                para1: "blakjsfkjkjvdfj ,ks k kdjh  gkj jbgk lwhfg wu wih i kw vsbg kb wkb kw  kw gfksjhgf wkjbs",
            },
            {
                para2: "Nous travaillons avec des clients, petits et grands, de tout secteurs d'activite!!",
            },
            {
                para3: "Nous avons plusieurs differentes equipes a travers le monde pour vos fournir les meilleurs services la ou vous vous trouvez",
            }
        ],
        details: [
            {title: "Commencer une nouvelle entreprise est facile!!"},
            {
                para1: "Nos equipes sont au fait des dernieres technologies et ionnovations pour vous faciliter la vie!"
            },
            {
                quote: "bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bal bla"
            }
        ]
    },
]


export const popular = [
    {
        id:1,
        category: "World",
        title: "Our favourites photos from all around",
        date: "19, Febuary 2023",
        comments: 0,
        cover: cover09
    },
    {
        id:2,
        category: "Travel",
        title: "Our favourites photos from all around",
        date: "19, Febuary 2023",
        comments: 10,
        cover: cover05
    },
    {
        id:3,
        category: "Community",
        title: "Our favourites photos from all around",
        date: "19, Febuary 2023",
        comments: 5,
        cover: cover06
    },
    {
        id:4,
        category: "Community",
        title: "Our favourites photos from all around",
        date: "19, Febuary 2023",
        comments: 5,
        cover: cover06
    },
    {
        id:5,
        category: "Community",
        title: "Our favourites photos from all around",
        date: "19, Febuary 2023",
        comments: 5,
        cover: cover06
    },
    {
        id:6,
        category: "Community",
        title: "Our favourites photos from all around",
        date: "19, Febuary 2023",
        comments: 5,
        cover: cover06
    },
    {
        id:7,
        category: "Community",
        title: "Our favourites photos from all around",
        date: "19, Febuary 2023",
        comments: 5,
        cover: cover06
    },
    {
        id:8,
        category: "Community",
        title: "Our favourites photos from all around",
        date: "19, Febuary 2023",
        comments: 5,
        cover: cover06
    },
    {
        id:9,
        category: "Community",
        title: "Our favourites photos from all around",
        date: "19, Febuary 2023",
        comments: 5,
        cover: cover06
    },
]

export const pub = [
    {titre:"first publicity",
     image:image01},
     {titre:"second publicity",
     image:image01}, 
]

export const submenu = [
    {id:1,
    menu: 'Etudes',
    data: [
        {image1: imageS1,
         id:1,
         titre: "Etudiez au Canada et en Russie!!"},
         {image1: imageS1,
            id:2,
            titre: "Etudiez au Canada et en Russie!!"},
            {image1: imageS1,
                id:3,
                titre: "Etudiez au Canada et en Russie!!"},
                {image1: imageS1,
                    id:4,
                    titre: "Etudiez au Canada et en Russie!!"},
                    {image1: imageS1,
                        id:5,
                        titre: "Etudiez au Canada et en Russie!!"},
    ]},
    {id:2,
        menu: 'Etudes',
        data: [
            {image1: imageS2,
             id:6,
             titre: "Restrospectives universelles"},
             {image1: imageS2,
                id:7,
                titre: "Restrospectives universelles"},
                {image1: imageS2,
                    id:8,
                    titre: "Restrospectives universelles"},
                    {image1: imageS2,
                        id:9,
                        titre: "Restrospectives universelles"},
                        {image1: imageS2,
                            id:10,
                            titre: "Restrospectives universelles"},
        ]},
        {id:3,
            menu: 'Etudes',
            data: [
                {image1: imageS1,
                 id:11,
                 titre: "Etudiez au Canada et en Russie!!"},
                 {image1: imageS1,
                    id:12,
                    titre: "Etudiez au Canada et en Russie!!"},
                    {image1: imageS1,
                        id:13,
                        titre: "Etudiez au Canada et en Russie!!"},
                        {image1: imageS1,
                            id:14,
                            titre: "Etudiez au Canada et en Russie!!"},
                            {image1: imageS1,
                                id:15,
                                titre: "Etudiez au Canada et en Russie!!"},
            ]},
            {id:4,
                menu: 'Etudes',
                data: [
                    {image1: imageS2,
                     id:16,
                     titre: "Restrospectives universelles"},
                     {image1: imageS2,
                        id:17,
                        titre: "Restrospectives universelles"},
                        {image1: imageS2,
                            id:18,
                            titre: "Restrospectives universelles"},
                            {image1: imageS2,
                                id:19,
                                titre: "Restrospectives universelles"},
                                {image1: imageS2,
                                    id:20,
                                    titre: "Restrospectives universelles"},
                ]},
                {id:5,
                    menu: 'Etudes',
                    data: [
                        {image1: imageS1,
                         id:21,
                         titre: "Etudiez au Canada et en Russie!!"},
                         {image1: imageS1,
                            id:22,
                            titre: "Etudiez au Canada et en Russie!!"},
                            {image1: imageS1,
                                id:23,
                                titre: "Etudiez au Canada et en Russie!!"},
                                {image1: imageS1,
                                    id:24,
                                    titre: "Etudiez au Canada et en Russie!!"},
                                    {image1: imageS1,
                                        id:25,
                                        titre: "Etudiez au Canada et en Russie!!"},
                    ]},
                    {id:6,
                        menu: 'Etudes',
                        data: [
                            {image1: imageS2,
                             id:26,
                             titre: "Restrospectives universelles"},
                             {image1: imageS2,
                                id:27,
                                titre: "Restrospectives universelles"},
                                {image1: imageS2,
                                    id:28,
                                    titre: "Restrospectives universelles"},
                                    {image1: imageS2,
                                        id:29,
                                        titre: "Restrospectives universelles"},
                                        {image1: imageS2,
                                            id:30,
                                            titre: "Restrospectives universelles"},
                        ]}
]